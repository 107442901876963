import { IAlbum } from "../../common/Album";

const album: IAlbum = {
  title: "Allegory Alley",
  date: new Date(2025, 1, 16),
  img: "/img/asteroid.jpeg",
  tracks: [
    {
      title: "Rainy Morning Jazz",
      src: "/songs/2025-02-16/rainy_morning_jazz.mp3",
      duration: 364.104,
    },
    {
      title: "Fiddlesticks",
      src: "/songs/2025-02-16/fiddlesticks.mp3",
      duration: 401.928,
    },
    {
      title: "Incantation",
      src: "/songs/2025-02-16/incantation.mp3",
      duration: 794.544,
    },
    {
      title: "Q & A",
      src: "/songs/2025-02-16/q_and_a.mp3",
      duration: 905.568,
    },
    {
      title: "If You Know Me",
      src: "/songs/2025-02-16/if_you_know_me.mp3",
      duration: 430.944,
    },
    {
      title: "Smells Like Funk",
      src: "/songs/2025-02-16/smells_like_funk.mp3",
      duration: 395.64,
    },
    {
      title: "Azure Blues",
      src: "/songs/2025-02-16/azure_blues.mp3",
      duration: 488.376,
    },
    {
      title: "Point Of Inflection",
      src: "/songs/2025-02-16/point_of_inflection.mp3",
      duration: 696.672,
    },
    {
      title: "Travelers From The Future",
      src: "/songs/2025-02-16/travelers_from_the_future.mp3",
      duration: 549.192,
    },
    {
      title: "Down Periscope",
      src: "/songs/2025-02-16/down_periscope.mp3",
      duration: 526.992,
    },
    {
      title: "Crack Spackle Pop",
      src: "/songs/2025-02-16/crack_spackle_pop.mp3",
      duration: 490.416,
    },
    {
      title: "Earth Killing Asteroid",
      src: "/songs/2025-02-16/earth_killing_asteroid.mp3",
      duration: 722.76,
    },
    {
      title: "Long Lonely Night",
      src: "/songs/2025-02-16/long_lonely_night.mp3",
      duration: 2184.408,
    },
    {
      title: "Lunar Base",
      src: "/songs/2025-02-16/lunar_base.mp3",
      duration: 610.776,
    },
    {
      title: "Sharks And Dolphins",
      src: "/songs/2025-02-16/sharks_and_dolphins.mp3",
      duration: 587.568,
    },
    {
      title: "Light In Your Eyes",
      src: "/songs/2025-02-16/light_in_your_eyes.mp3",
      duration: 483.432,
    },
    {
      title: "Saving Grace",
      src: "/songs/2025-02-16/saving_grace.mp3",
      duration: 1390.752,
    },
    {
      title: "Taking The Back Roads Home",
      src: "/songs/2025-02-16/taking_the_back_roads_home.mp3",
      duration: 960.552,
    },
    {
      title: "Last Call For Stew",
      src: "/songs/2025-02-16/last_call_for_stew.mp3",
      duration: 716.808,
    },
  ],
};
export default album;
