import { IAlbum } from "../../common/Album";

const album: IAlbum = {
  title: "Domino Effect",
  date: new Date(2025, 2, 16),
  img: "/img/domino.jpeg",
  tracks: [
    {
      title: "Turning The Page",
      src: "/songs/2025-03-16/turning_the_page.mp3",
      duration: 1888.56,
    },
    {
      title: "Open Windows",
      src: "/songs/2025-03-16/open_windows.mp3",
      duration: 1630.176,
    },
    {
      title: "A Million Faces",
      src: "/songs/2025-03-16/a_million_faces.mp3",
      duration: 1310.88,
    },
    {
      title: "Bit By Bit",
      src: "/songs/2025-03-16/bit_by_bit.mp3",
      duration: 498.096,
    },
    {
      title: "Bluesy Boots",
      src: "/songs/2025-03-16/bluesy_boots.mp3",
      duration: 2158.128,
    },
    {
      title: "Uranium Cranium",
      src: "/songs/2025-03-16/uranium_cranium.mp3",
      duration: 389.976,
    },
    {
      title: "Disco Dance Party",
      src: "/songs/2025-03-16/disco_dance_party.mp3",
      duration: 1016.76,
    },
    {
      title: "What You're Doing To Me",
      src: "/songs/2025-03-16/what_youre_doing_to_me.mp3",
      duration: 2222.616,
    },
  ],
};
export default album;
