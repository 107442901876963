import { IAlbum } from "../../common/Album";

const album: IAlbum = {
  title: "All The Queens Men",
  date: new Date(2025, 2, 23),
  img: "/img/queen.jpeg",
  tracks: [
    {
      title: "Jungle Gym",
      src: "/songs/2025-03-23/jungle_gym.mp3",
      duration: 338.688,
    },
    {
      title: "Panther Pink",
      src: "/songs/2025-03-23/panther_pink.mp3",
      duration: 1037.016,
    },
    {
      title: "Venturing Out",
      src: "/songs/2025-03-23/venturing_out.mp3",
      duration: 282.072,
    },
    {
      title: "In For A Penny",
      src: "/songs/2025-03-23/in_for_a_penny.mp3",
      duration: 677.544,
    },
    {
      title: "As Above So Below",
      src: "/songs/2025-03-23/as_above_so_below.mp3",
      duration: 288.576,
    },
    {
      title: "Marching Orders",
      src: "/songs/2025-03-23/marching_orders.mp3",
      duration: 780.336,
    },
    {
      title: "Moment Of Reckoning",
      src: "/songs/2025-03-23/moment_of_reckoning.mp3",
      duration: 622.752,
    },
    {
      title: "Bass Ball",
      src: "/songs/2025-03-23/bass_ball.mp3",
      duration: 506.208,
    },
    {
      title: "Good Time Machine",
      src: "/songs/2025-03-23/good_time_machine.mp3",
      duration: 888.672,
    },
    {
      title: "Level Playing Field",
      src: "/songs/2025-03-23/level_playing_field.mp3",
      duration: 1075.752,
    },
  ],
};
export default album;
