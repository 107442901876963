import { IAlbum } from "../../common/Album";

const album: IAlbum = {
  title: "Forest Through the Trees",
  date: new Date(2025, 2, 2),
  img: "/img/forest.jpeg",
  tracks: [
    {
      title: "Springtime Sunrise",
      src: "/songs/2025-03-02/springtime_sunrise.mp3",
      duration: 389.832,
    },
    {
      title: "Meander Tall",
      src: "/songs/2025-03-02/meander_tall.mp3",
      duration: 680.904,
    },
    {
      title: "Definitive Lee",
      src: "/songs/2025-03-02/definitive_lee.mp3",
      duration: 732.312,
    },
    {
      title: "Holding Pattern",
      src: "/songs/2025-03-02/holding_pattern.mp3",
      duration: 812.856,
    },
    {
      title: "Sea Sponges",
      src: "/songs/2025-03-02/sea_sponges.mp3",
      duration: 1017.792,
    },
    {
      title: "Tipping The Scales",
      src: "/songs/2025-03-02/tipping_the_scales.mp3",
      duration: 1005.864,
    },
    {
      title: "Floating Down The River",
      src: "/songs/2025-03-02/floating_down_the_river.mp3",
      duration: 1232.184,
    },
    {
      title: "Every Day Thinking",
      src: "/songs/2025-03-02/every_day_thinking.mp3",
      duration: 2217.336,
    },
    {
      title: "Lower & Slower",
      src: "/songs/2025-03-02/lower_and_slower.mp3",
      duration: 662.328,
    },
    {
      title: "Constant Variable",
      src: "/songs/2025-03-02/constant_variable.mp3",
      duration: 619.056,
    },
    {
      title: "0-60 In Twenty Minutes",
      src: "/songs/2025-03-02/zero_to_sixty.mp3",
      duration: 1195.608,
    },
  ],
};
export default album;
